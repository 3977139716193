import React from 'react'

import { Card, Container , Image } from 'react-bootstrap'
import vineGripaImg from '../../../images/Articole/4_Vine_gripa.jpg'
import sanatateaCopiilorImg from '../../../images/Articole/1_ce_facem_cu_sanatatea_copiilor_nostri.jpg'
import despreInfertilitateImg from '../../../images/Articole/3_Despre_infertilitate.jpg'
import durereaAbdominalaImg from '../../../images/Articole/5_Durerea_abdominala__o_problema_comuna.jpg'
import litiazaImg from '../../../images/Articole/6_Dieta_pentru_litiaza_renala.jpg'
import tensiuneaImg from '../../../images/Articole/2_Ce_facem_pentru_tensiunea_oscilanta.jpg'

const ArticoleUtile = () => {
  

  return (
    <Container>
      <Card className='box-shadow-left m-3 p-3'>
        <div>
          <h2>Gripa</h2>
          <Image src={vineGripaImg} className='image-container-right'/>
          <p>
            Gripa este o boală infecțioasă acută, determinată de virusul gripal, 
            caracterizată prin manifestări respiratorii și generale severe și printr-o mare 
            contagiozitate, cu apariția de epidemii frecvente. Până astăzi au apărut 31 de pandemii 
            de gripă, cea din 1918-1920 a înregistrat 
            aproximativ 50-100 milioane de morți (cea mai mare molima cunoscută vreodată pe această planetă).
          </p>
          <p>
            În 1933, s-a izolat tipul A de virus gripal urmând apoi celelalte serotipuri. În 1940, virusul gripal
            a fost cultivat pe oul embrionat, ceea ce a permis elaborarea vaccinului antigripal.
          </p>
          <p>
            Virusul gripal de actiuneaza asupra mucoasei respiratorii, dar exercită și o acțiune 
            toxică generală asupra întregului organism ( un sindrom de intoxicație generală), 
            exprimat prin febra, cefalee, dureri musculare, astenie și somnolență.
          </p>
          <p>
            Epidemiile de gripă apar, de obicei, iarnă și la începutul primăverii, sezonul 
            friguros constituind o circumstanță favorabilă. Epidemiile pot apare însă și în alte sezoane.
          </p>
          <p>
            Transmiterea bolii se realizează direct, prin picături de secreție nazo-faringiană răspândite în 
            aer și indirect prin obiecte proaspăt contaminate cu secreții infectate.
          </p>
          <p>
            Răspândire este foarte rapidă, cuprinzând țări și continente în câteva 
            săptămâni sau luni. Un bolnav este contagios între 3 și 5 zile
          </p>
          <p>
            După o perioada de 1-3 zile de incubație, gripă debutează brusc prin creșterea rapidă a 
            temperaturii (39-40°C), dureri musculare, dureri de cap, astenie marcată, lipsa poftei 
            de mâncare, greață și vărsături. Simptomele respiratorii pot să lipsească uneori complet. 
            În mod obișnuit nasul este înfundat sau persoană prezintă guturai, 
            roșeața la nivelul faringelui, senzație de răgușeală, tușe frecvența uscată obositoare. 
          </p>
          <p>
            Prevenția se realizează prin administrarea de vaccin gripal persoanelor cu risc crescut de
              obicei în lunile octombrie și ianuarie. Imunitatea produsă de vaccin este de un an de zile.
          </p>
          <p>
            Tratamentul curativ obligatoriu este repausul la domiciliu 5-7 zile în camera separată 
            față de ceilalți membri ai familiei, folosirea batiștei la gură când se vorbește sau 
            dacă tușiți, alimentați-va normal dar mai bogat în vitamina C (lămâi), hidratați-va 
            cu ceai 1-2 litri în 24 ore, care stimulează diureza și elimina toxinele. Pentru 
            febra luați paracetamol, fervex sau alte antinflamatoare. Aspirina se administrează 
            cu prudență deoarece produce vasodilatație 
            (pericol de hemoragie nazala sau gingivale), iar antibioticele sunt rezervate doar complicațiilor.
          </p>
        </div>
        <div>
          <h2 className='m-3 p-3'>Dieta pentru litiaza renala</h2>
          <Image src={litiazaImg} className='image-container-right'/>
          <p>
            Pietrele renale reprezintă bucăți de minerale localizate la nivelul tractului urinar 
            ce pot rămâne la acest nivel sau pot fi eliminate din organism. Drumul calculilor poate 
            fi asimptomatic sau poate produce o durere intensă asociată cu alte simptome (așa numită colica renală). 
            Aproximativ jumătate dintre cei care au cel puțîn un calcul renal 
            vor dezvoltă noi calculi în următorii 5 ani dacă nu este urmat nici un tratament.
          </p>
          <p>
            Calculii renali se formează atunci când balanță dintre apă, sarurile,
             mineralele și alte substanțe din urină se modifică. Cele mai multe pietre sunt 
            formate din calciu și apar atunci când nivelul acestei substanțe în urină se modifică.
          </p>
          <p>
            Factorii care determina modificarea concentrație sarurilor din urină sunt multiple. 
            Prin lipsa unui aport suficient de lichide sarurile, mineralele și alte substanțe din 
            urină precipită și formează calculi. Unele afecțiuni medicale pot predispune la litiaza, 
            boală inflamatorie intestinală sau intervențiile chirurgicale la nivelul intestinului scade 
            absorbția normală a grăsimilor și acestea interfera cu modul în care este metabolizat calciul 
            și alte minerale. Alimentele bogate în oxalati, precum sunt legumele verzi și ciocolată,
             predispun la apariția bolii. Litiaza renală poate fi și o boală moștenită, care apare de-a 
             lungul mai multor generații ale aceleiași familii. În cazuri rare, pot apare calculi renali 
             datorită producției crescute de hormoni de către glandele 
            paratiroide, ceea ce determina creșterea calciului în sânge și depunerea lui în rinichi.
          </p>
          <p>
             În funcție de natură pietrelor se recomandă o dietă specifică. În litiaza calcică este necesar 
             un consum de lichide de 1,5-2 L/24 ore ( apă plata, ceaiuri de plante, eventual diuretice ). 
             Sunt de evitat apele minerale alcaline. Se va reduce aportul de alimente bogate în calciu și 
             fosfor ( lapte și derivate din lapte, brânzeturi, cașcaval, fasole uscată, nuci, cereale și ciocolată). 
             Aportul de sare va fi redus la 2 - 4 g/ 24 ore și 
             se evita alimentele conservate prin sare. Medicul va poate recomandă și tratament diuretic.
          </p>
          <p>
            În litiaza oxalica la măsurile recomandate anterior aș mai adaugă: evitarea alimentelor
             bogate în oxalati (măcriș, sfeclă, roșii, ceai negru sau verde, portocală, vitamina C în 
             exces, cacao sau ciocolată ) și un 
            tratament diuretic cu supliment de fosfați, care împiedică eliminarea de oxalati prin urină.
          </p>
          <p>
            Calculii pot cauzati de excesul de fosfați. După determinarea naturii lor printr-un 
            examen de urină, se vor administra săruri de aluminiu care împiedică absorbția digestivă a 
            fosfatatilor, iar dacă urină este alcalină se folosesc acidifiante ale urinii ( vitamina C).
          </p>
          <p>
            Pietrele renale care sunt determinate de urați pot fi prevenite și eliminate prin 
            cură de diureza ( peste 2l / 24 ore) și reducerea consumului de alimente care cresc 
            concentrația de acid uric din sânge (viscere, carne de animal tânăr, vânat, sardele, 
            supe de oase, mezeluri, ciocolată, spanac, brânză fermentată, ciuperci, fasole, mazăre, 
            linte și bob). Se recomandă carne de maxim 
            trei ori pe săptămâna, lactate proaspete, ouă, fructe și legume cu excepția celor interzise.
          </p>
          <p>
            Foarte important este evitarea consumului mare de lichide în timpul crizei dureroase deoarece 
            acesta va amplifică simptomatologia. La cabinetul nostru puteți 
            beneficia de consultație și ecografie uro-vezicală de urgență în timpul colicii renale.
          </p>
        </div>
        <div>
          <h2 className='m-3 p-3'>Durerea abdominală - o problema comună</h2>
          <Image src={durereaAbdominalaImg} className='image-container-right'/>
          <p>
             Indigestia, discomfortul abdominal, imbalonarea sau senzația de sățietate timpurie 
             sunt simptome frecvente care ne strică bună dispoziție a sărbătorilor. 
             Diagnosticul pe care îl primim este de dispepsie sau sindrom dispeptic. 
             Cea mai frecvența cauza este funcțională, neulceroasa, datorată faptului 
             că organismul nostru nu este obișnuit cu un festin prelungit și "stropit" 
             din belșug. Datorită exceselor alimentare pot fi activate afecțiuni preexistente 
             ale stomacului (gastrită, ulcer), veziculei biliare și intestinelor ducând la 
             simptome mai severe precum greață, vomă și scăderea apetitului. Nu trebuie 
             neglijate boli cu potetial grav: infarct miocardic, angină instabilă, anevrism de aorta, 
             pneumonie, litiază sau infecție renală, apendicită sau chiar cancerul.
          </p>
          <p>
            În cazul în care trusa dumneavoastră de urgență nu va ameliorează suferință este 
            necesar să consultați medicul (inițial telefonic dacă este posibil). 
            Este important să precizați dacă afecțiunea este agravată de masă; dacă simțiți 
            o durere intensă când va apăsăți pe abdomen; dacă ați luat medicamente 
            antiinflamatoare (ex. aspirina, ibuprofen, diclofenac); dacă aveți diaree sau 
            constipație; dacă ați scăzut în greutate; dacă aveți dificultăți de a înghiți; 
            dacă durerea se extinde sau vine dinspre spate ori are caracter de arsură la 
            nivelul pieptului și cât de des apar unele simptomele că vomă, transpirațiile sau oboseală?
          </p>
          <p>
            Nu a fost încă stabilit cel mai bun protocol de diagnostic pentru tulburările funcționale. 
            Asociația de Gastroenterologie Americană recomandă că: pacienții peste 55 de ani sau care au 
            simptome importante (durere, vomă, scădere în greutate, anemie) să facă o investigație numită 
            endoscopie digestivă superioară și colonoscopie, iar cei sub 55 de ani sau care au o 
            afectare ușoară, să facă teste pentru detectarea bacteriei Helicobacter Pylori din sânge și scaun.
          </p>
          <p>
            Diagnosticul de dispepsie funcțională poate fi o ușurare pentru unii care credeau că 
            suferă o boală gravă sau frustrant pentru alții. Este foarte important de a înțelege 
            simptomele și factorii care le declanșează sau agravează. Una sau mai multe metode de 
            tratament sunt de obicei recomandate și este foarte important pentru evitarea frustrărilor 
            că medicul să va indice așteptări realiste de ameliorare a bolii. Dacă aveți o stare 
            sufletească alterată, anxioasă sau depresivă, o discuție cu un psiholog este de asemenea utilă.
          </p>
          <p>
            Alimentele care simțiți că va agravează simptomele trebuiesc eliminate și 
            cel mai corect puteți stabili o lista cu ele împreună cu medicul dumneavoastră 
            de familie. Articolele noastre sunt o sursă de informații promptă și competență 
            la întrebările dumneavoastră medicale. Deoarece aspectul boli variază de la o 
            persoană la altă este important să-mi scrieți pe e-mail office@my-clinic.ro pentru a 
            să va ghida spre cauzele reale ale afecțiunilor medicale care va preocupă.
          </p>
        </div>
        <div>
          <h2 className='m-3 p-3'>Despre infertilitate</h2>
          <Image src={despreInfertilitateImg} className='image-container-right'/>
          <p>
            Infertilitatea este definită că fiind incapacitatea unui cuplu de a concepe după 
            aproximativ un an de raporturi sexuale neprotejate. În fiecare an aproximativ 15% 
            dintre familii se confruntă cu această problema. Situația poate fi devastatoare pentru 
            ambii parteneri, iar investigațiile necesită un efort important. Din fericire noi 
            protocoale de investigații și 
            ghiduri de tratament sunt disponibile pentru cuplurile ce nu pot avea încă copii.
          </p>
          <p>
            Fertilitatea depinde de o serie de factori ai ambilor parteneri. 
            Problemele au cauze: masculine - 20%, feminine -40%, ale ambilor parteneri - 30% și neprecizate - 10%. 
            Descoperirea etiologiei trebuie să pornească cu investigarea ambilor parteneri.
          </p>
          <p>
            Fecunditatea masculină necesită o funcționare normală a hipotalamusului, 
            glandei hipofize și testiculelor. De aceea, numeroase afecțiuni medicale pot duce la 
            o fertilitate scăzută. Evaluarea medicală inițială va cuprinde un istoric al bolilor, 
            un examen clinic și un test de spermă. În general recomand căutarea problemelor de 
            concepere întâi la bărbați deoarece costul investigațiilor este mic și se obține un 
            diagnostic mult mai rapid. Se evita astfel o serie de proceduri medicale costisitoare 
            și dificile din partea femeii fără a avea o viziune completă asupra ambilor parteneri. 
            Spermograma ne va da informații despre cantitatea, numărul, motilitatea și aspectul spermatozoizilor. 
            În U.S. și unele țări europene este aprobat un test de evaluare pentru acasă - Fertell, care are o 
            fiabilitate de 95% față de laborator. Uneori pot fi necesare analize de sânge (dereglări hormonale), 
            teste genetice (anomalii cromosomale) și chiar biopsia testiculară (depistarea spermatozoizilor).
          </p>
          <p>
            Deși sunt disponibile o varietate de metode de diagnostic pentru evaluarea fertilității 
            feminine, nu sunt toate necesare. La prima consultație, medicul va face un istoric al 
            bolilor, un examen fizic și o serie de teste preliminare. Se va începe cu un examen de 
            secreție a colului uterin pentru bacteriile Chlamydia și Mycoplasma. În ziua a treia a 
            ciclului menstrual se vor evalua următorii hormoni din sânge: FSH, LH, testosteron, 
            estradiol, prolactina, iar în ziua 21 - progesteronul. Deoarece bolile tiroidei sunt o 
            cauza frecvența de infertilitate se recomandă și o analiză a hormonilor TSH și T4(free). 
            Există, de asemeni, 
            în unele țări disponibile teste de urină pentru acasă care pot sesiza unele probleme hormonale.
          </p>
          <p>
            În urmă unei investigații corecte și a unor măsuri și tratamente adecvate 60-70% dintre 
            femeile din cuplurile cu probleme vor concepe în 3 ani. Dar tot acest proces de a rămâne 
            însărcinată poate duce la o varietate de stări emoționale precum anxietate, depresie, furie, 
            jenă și sentiment de vinovăție. 40 % dintre pacienți suferă din cauza stresului asociat, 
            ceea ce determina diminuare suplimentară a fertilității. Așadar suportul medical trebuie completat de 
            cel psihologic prin tehnici de relaxare, managementul stresului și consilierea cuplului.
          </p>
        </div>
        <div>
          <h2 className='m-3 p-3'>Ce facem pentru tensiunea oscilanta?</h2>
          <Image src={tensiuneaImg} className='image-container-right'/>
          <p>
            Mulți pacienți se plâng că au tensiunea arterială oscilantă. Le răspund că este 
            normal să varieze în funcție de poziția corpului, tipul de personalitate, statusul 
            emoțional, locația, activitatea, sexul, vârstă, muncă, momentul zilei, perioada anului etc. 
            Cel mai mult contează valorile maxime ale tensiunii arteriale dar obligatoriu raportate la 
            starea și activitatea curentă. Un tratament corect trebuie început după o urmărirea atentă a 
            tensiunii, câteva zile la ore diferite. Dacă nu aveți un aparat de tensiune acasă (verificat) 
            acest control poate fi destul de dificil. Soluția este monitorizarea ambulatorie a tensiunii 
            arteriale (MTA), o investigație neinvazivă ce constă în măsurarea presiunii sângelui la intervale 
            regulate pe parcursul a 24 de ore. 
            Această metodă standard este des recomandată de cardiologi, dar mai rar folosită în practică clinică.
          </p>
          <p>
            Investigația se desfășoară pe parcursul unei singure perioade de 24 ore sau mai multe zile. 
            În acest timp, aparatul este programat să se umfle și să înregistreze TA la intervale 
            prestabilite (de obicei la 15 min. în timpul zilei și la fiecare 30 min. în timpul nopții), 
            ceea ce ne asigura aproximativ 50-75 de măsurători ale valorilor tensionale. O serie de factori 
            care pot influență TA vor fi înregistrați. Astfel pacienții sunt sfătuiți să-și mențînă 
            activitățile obișnuite și să menționeze într-un jurnal toate activitățile și evenimentele 
            zilei. Aparatul poate declanșa măsurători suplimentare ori de câte ori pacientul 
            consideră că este necesar (amețeli, dureri de cap, zgomote în urechi, amorțeli ale membrelor etc.).
          </p>
          <p>
            Raportul final conține: valorile înregistrate în 24h, 
            ritmul cardiac, media TA și statistici raportate la cele 24h și la perioadele de zi și noapte.
          </p>
          <p>
            Mai multe situații clinice impun folosirea acestei metode. HTA de ”halat alb” sau de stress - 
            este poate cea mai mediatizată indicație pentru MTA. Această este un fenomen prin care unii pacienți 
            care aparent au crescută TA au valori normale când măsurarea este repetată în afară cabinetului medical.
             Acești pacienți pot primi un diagnostic eronat de hipertensiune cronică. Acest fenomen a fost raportat 
             la 15-35% dintre pacienții “etichetați” și tratați. Tensiunea de prag, la limita, include 
             persoanele cu nivele ușor crescute peste normal ale TA. Tensiunea arterială rezistență reprezintă 
             imposibilitatea de a atinge un nivel sub 140/90 mmHg deși se folosește un tratament adecvat. 
             Alte indicații foarte importante mai sunt hipertensiunea nocturnă (știindu-se că multe accidente 
             vasculare și infarcturi se produc în somn), 
            tensiunea arterială în sarcina și hipotensiunea arterială.
          </p>
          <p>
            Datorită măsurătorilor disparate obținute în urmă unor vizite neregulate la cabinet, 
            medicii sunt puși într-o situație dificilă de a institui o terapie farmacologica. 
            Diagnosticul și tratamentul HTA este important pentru prevenirea pe termen lung a 
            complicațiilor cerebrovasculare și cardiovasculare. Trebuie să fiți familiarizați 
            cu modalitățile eficiente de diagnostic pentru a primi cel mai bun tratament. 
            La cabinetul nostru va puteți monitoriza astfel tensiunea arterială corect și profesionist.
          </p>
        </div>
        <div>
          <h2 className='m-3 p-3'> Ce facem cu sănătatea copiilor noștri? </h2>
          <Image src={sanatateaCopiilorImg} className='image-container-right'/>
          <p>
            În fiecare an se desfășoară Conferință Națională de Pediatrie. 
            Cu această ocazie sunt lansate Ghidurile și protocoalele în pediatrie.
          </p>
          <p>
            Ele reprezintă recomandări de bună practică obținute pe dovezi publicate 
            sau acceptate prin consens internațional și național. 
            Scopul lor este de a asista medicii în luarea decizilor medicale.
          </p>
          <p>

            Îndată ce temperatura de afară scade se observă o creștere alarmantă a afecțiunilor 
            respiratorii la adulți și copii. Fiind la rândul meu părinte, înțeleg îngrijorarea dusă 
            spre extreme când avem copilul bolnav. Doresc să va ofer câteva informații certe care ar 
            trebui cunoscute de fiecare părinte conform recomandărilor 
            Ghidului pentru diagnosticul și tratamentul infecțiilor respiratorii acute la copii:
          </p>
          <p>
            Răceală este cea mai frecvența boală acută la copii. În primii ani de viață, majoritatea 
            copiilor dezvoltă 6-8 episoade pe an. După 6 ani, frecvența episoadelor se rărește. 
            Apariția acestor afecțiuni este mai mare decât la adult deoarece 
            sistemul lor imun nu este complet măturat iar contactul cu alți copii în colectivitate este mai intim.
          </p>
          <p>
            Este mai frecvența în sezonul rece deoarece virusurile se dezvoltă mai bine în 
            aceste condiții și întrucât copii petrec mai mult timp în încăperi.      
             Este cauzată de mai multe tipuri de virusuri, 
            dintre care cel mai frecvent produc boală rhinovirusurile, apoi cele gripale și paragripale.
          </p>
          <p>
            Boală debutează brusc și se agravează progresiv în primele 3-4 zile. 
            Febra durează 1-3 zile. Simptomele dispar în 7-14 zile și tusea poate persistă 3-4 săptămâni.
          </p>
          <p>
             Nu există tratament care vindecă sau scurtează evoluția bolii. Antibioticele nu sunt de 
             folos în majoritatea situațiilor (1 din 10 copii are nevoie de ele). În plus, aceste 
             medicamente au efecte secundare neplăcute (greață, vărsături, alergii etc.) care pot 
             înrăutăți starea copilului. Dacă sunt utilizate 
             prea des nu vor mai fi eficiente. Și nu în ultimul rând duc la cheltuieli suplimentare inutile.
          </p>
          <p>
            Se va evita fumatul în încăperile în care doarme sau își petrece timpul copilul. Fumul de 
            țigară irită căile respiratorii și întârzie vindecarea bolii.
          </p>
          <p>
            Dacă febra crește se poate administra paracetamol sau ibuprofen. Nu este indicat 
            metamizolul (Algocalmin) datorită 
            reacțiilor adverse severe decât în cazurile care nu răspund la combinația paracetamol cu ibuprofen.
          </p>
          <p>Remediile care pot ameliora simptomatologia sunt: aportul suplimentar de lichide care 
            mențîn secrețiile respiratorii în stare fluidă, ușor de eliminat; instilatiile nazale 
            cu ser fiziologic care facilitează îndepărtarea secretiilor, îndepărtarea mecanică a 
            acestora cu pompă sau tampoane de vată și umidificati atmosfera așezând prosoape umede pe calorifer.
             Se poate folosi baia de aburi, 
            dând drumul la dus cu apă fierbinte. Copilul va fi ținut în această atmosfera 10-30 de minute.</p>
          <p>
            Consultați urgent medicul dacă: respirația devine dificilă când este liniștit; 
            devine palid sau se învinețește; este agitat, iritabil sau delireaza; temperatura 
            crește peste 39°C; are dificultăți de înghițire a salivei; 
            apare o erupție pe piele sau pentru orice motiv de îngrijorare sau nesiguranță.
          </p>
          <p>Este foarte important să liniștiți copilul, eventual țînându-l în brațe sau citindu-i o 
            poveste, deoarece agitația înrăutățește respirația. 
            Va doresc să aveți răbdare și să rezistați tentației de a supratrata o afecțiune comună și necomplicată.</p>
        </div>
      </Card>
    </Container>
  )
}

export default ArticoleUtile