import React from "react";
import { Container, Card, Image } from "react-bootstrap";
import stoneImg from "../../../images/Psihologie/1_psihoterapie.jpg";

const Psihoterapie = () => {
  return (
    <Container>
      <Card className="box-shadow-left m-3 p-3">
        <h1 className="m-3 p-3 d-flex justify-content-center">
          Psihoterapie - calea spre stabilitate
        </h1>
        <div>
          <div className="d-flex justify-content-center">
            <Image src={stoneImg} className="mb-4" />
          </div>
          <p>
            Psihoterapia este tipul de activitate psihologică profesională
            centrată pe relația de ajutor în situațiile de criză personală sau
            colectivă, de impas existențial iminent sau trenant, de dificultate
            în adaptare, relaționare și integrare socială.
          </p>
          <p>
            Psihoterapia va poate fi de folos ori de câte ori va confruntați cu
            :
          </p>
          <p>
            - blocarea capacităților uzuale de a face față provocărilor vieții,
            că și problemele survenite în educarea și dezvoltarea lor;
          </p>
          <p>
            - în situații de impas existențial, pierderi, traume, suferință
            psihică și somatică în contextul anumitor maladii, intervenții
            chirurgicale, accidente, etc.
          </p>
          <p>
            Confruntați cu o diversitate de probleme, cu stress-ul cotidian în
            creștere (economic, socio-familial, profesional, politic, ecologic),
            oamenii de cele mai diverse vârste, profesii, culturi, etnii,
            ideologii și religii traversează perioade cu risc dezadaptativ mai
            mult sau mai puțîn semnificativ.
          </p>
          <p>
            Oricare ar fi diferențele dintre noi, de la persoanele înalt
            capacitate și performanțe, la cele excluse sau marginalizate social,
            cu toții avem nevoie de ajutor specializat pentru a putea depăși
            obstacole diverse în calea dezvoltării noastre, a relaționării cu
            semenii, în lupta pentru supraviețuire și de integrare a traumelor.
          </p>
        </div>
      </Card>
    </Container>
  );
};

export default Psihoterapie;
