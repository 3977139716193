import React from 'react'
import { Container , Card} from 'react-bootstrap'
import { Link } from 'react-router-dom'

const gdpr = () => {
  return (
    <Container>
      <Card className='box-shadow-left m-3 p-3'>
        <h2 className='text-center m-3 p-3'>NOTA DE INFORMARE PRIVIND PRELUCRAREA DATELOR CU 
          CARACTER PERSONAL SI POLITICA PRIVIND PRELUCRAREA DATELOR CU CARACTER PERSONAL
        </h2>
        <p><i>Aceasta Nota de informare este valabila incepand cu data de 17.12.2019.</i></p>
        <p>
          In anumite circumstante,<span className='span-myclinic' >My Clinic</span>, in calitate de operator de date cu caracter personal, 
          poate modifica prezenta politica privind prelucrarea datelor cu caracter personal 
          pentru a reflecta modificarile intervenite in legislatie, in practicile si 
          procedurile interne de prelucrare a datelor cu caracter personal, in 
          caracteristicile website-ului sau in progresele tehnologice realizate intr-o 
          anumita perioada de timp. In cazul in care vom aduce modificari ce vor schimba 
          modul de prelucrare sau utilizare a datelor dumneavoastra cu caracter personal, 
          acestea vor fi publicate in cuprinsul politicii actualizate, iar data intrarii in 
          vigoare a politicii astfel modificate va fi publicata la inceputul notei de 
          informare privind prelucrarea datelor cu caracter personal actualizate. Prin 
          urmare, prezenta nota de informare privind prelucrarea datelor cu caracter 
          personal trebuie consultata periodic pentru a fi 
          la curent cu ultimele politici si practici intervenite in acest domeniu.
        </p>
        <p><b>
          In conformitate cu dispozitiile Regulamentului (UE) nr. 2016/679 privind 
          protectia persoanelor fizice in ceea ce priveste prelucrarea datelor cu caracter 
          personal si privind libera circulatie a acestor date si de abrogare a 
          Directivei 95/46/CE, cunoscut sub denumirea de „Regulamentul General privind 
          Protectia Datelor” sau, pe scurt, „RGPD”, va aducem la cunostinta urmatoarele aspecte 
          legate de prelucrarea datelor dumneavoastra cu caracter personal:
        </b></p>
        <ol>
          <div>
            <li><h4>Cine este operatorul datelor cu caracter personal?</h4></li>
            <p><span className='span-myclinic' >My Clinic</span> SRL persoana juridica romana cu sediul in Focșani</p>
          </div>
          <div>
            <li><h4>Ce date cu caracter personal prelucram?</h4></li>
            <h5>In cazul in care sunteti pacient <span className='span-myclinic' >My Clinic</span>:</h5>
            <p><b> Informatii generale </b>(date de identificare): nume, prenume, data nasterii, sex, 
              nationalitate, varsta, CNP, adresa de domiciliu sau de reședinta, e-mail, telefon,
               ID pacient, loc de munca (daca este cazul), calitatea de abonat (daca este cazul), 
               copie certificat de nastere, copie C.I., semnatura, iar in cazul in care veti fi internat, 
              datele de identificare ale apartinatorului (nume, prenume, grad de rudenie, telefon);
            </p>
            <p>
              <b> Date privind situatia debitelor: </b>istoric financiar in cadrul <span className='span-myclinic' >My Clinic</span>, situatia platilor restante;
            </p>
            <p><b>Date de sanatate sau conexe prestarii serviciilor medicale:</b></p>
            <p>
              <b> Date generale:</b> greutate, inaltime, calitatea de fumator ori nefumator, grupa sanguina, 
              RH, istoric medical, scrisori medicale, alergii, simptomatologie, diagnostic, 
              antecedente (heredocolaterale, fiziologice si patologice), tipul interventiei, 
              acte ce tin de activitatea medicala, cum ar fi: recomandare medic familie ori medic 
              specialist, raport medical, cod diagnostic, tipul de program prin care vine pacientul 
              (management boala cronica/acuta), semnatura pacientului, calitatea de abonat (dupa caz), 
              calitatea de asigurat CNAS sau de asigurat in sistemul privat (in cazul societatilor
               de asigurari private de sanatate), nr. card de sanatate, card de identificare oferit 
               de compania asiguratoare (dupa caz), bilet de trimitere, foaia de observatie, decont 
               cheltuieli si documente medicale din timpul spitalizarii (dupa caz), investigatii 
               efectuate, ziua si ora internarii, rezerva si pat pacient (in cazul in care veti fi internat),
                bilet externare, ora si ziua externarii, ziua/ora/locatia recoltarii probelor, 
                diagnostic prezumtiv, informatii evolutie stare de sanatate, alergii, medicatie 
                prescrisa pentru diverse patologii, 
              informatii medicale in urma realizarii consultului, talon pensie (dupa caz);
            </p>
            <p>
              <b> Date privind spitalizarea:</b> bilet de trimitere, foaia de observatie, 
              decont cheltuieli si documente medicale din timpul spitalizarii, 
              investigatii efectuate, ziua si ora internarii, rezerva si pat alocat, 
              observatii pacient cu privire la vizite, evolutia pacientului pe perioada spitalizarii, 
              bilet externare, ora si ziua externarii, diagnostic prezumtiv, alergii, medicatie 
              prescrisa pentru diverse patologii, informatii medicale in urma realizarii consultului, 
              talon pensie (dupa caz), nume, prenume si specialitate medic curant (dupa caz), 
              nume, prenume, grad de rudenie si telefon apartinator;
            </p>
            <p>
              <b>Date privind prestarea serviciului de medicina muncii: </b>functie detinuta si 
              fisa riscuri de la locuri de munca, adeverinta salariat;
            </p>
            <p>
              <b>Date privind prestarea serviciilor medicale de pediatrie:</b> nume si 
              prenume minor, sex, varsta, data nasterii, copie certificat de nastere minor, 
              dosar medical pediatric, certificat vaccinari;
            </p>
            <p>
              <b>Date necesare pentru teste prenatale non invazive:</b> varsta, rasa, sarcini in 
              antecedente, greutate, inaltime, calitatea de fumator sau nefumator;
            </p>
            <p>
              <b>Date privind analizele:</b> data si ora programarii, date privind 
              consultatiile si analizele, data, ora si locatia recoltarii, 
              codul de proba, radiologie imagistica s.a., rezultate analize;
            </p>
            <p><b><i>În cazul în care ne vei vizita într-una dintre locațiile noastre:</i></b></p>
            <ul>
              <li><b>Date privind imaginea:</b> imagine video captată prin intermediul camerelor de supraveghere
               amplasate în locuri vizibile în incintele noastre;
              </li>
            </ul>
            <p><b><i>În cazul în care vei apela la serviciul nostru Call Center:</i></b></p>
            <ul>
              <li><b>Datele privind vocea:</b>  vocea dumneavoastră și
               informațiile furnizate pe durata întregului apel 
              telefonic efectuat la serviciul non-stop ambulanță sau Call Center.
              </li>
            </ul>
            <p><b><i>În cazul în care accesezi website-ul <span className='span-myclinic' >My Clinic</span>:</i></b></p>
            <ul>
              <li><b> Date referitoare la profilarea utilizatorilor 
              website-ului <span className='span-myclinic' >My Clinic</span> conform Politicii de Cookies pe care o puteți accesa </b>  
              <Link to={"/footer/cookies"}>aici</Link> : 
              vârsta, sex, IP, date de trafic, date de geo-localizare, dispozitiv utilizat, sistem operare, 
              cuvinte cheie căutate de utilizator conform intereselor sale.
              </li>
            </ul>
          </div>
          <div>
            <li><h4>De unde obținem datele cu caracter personal?</h4></li>
            <p>
              Ca regulă, datele cu caracter personal pe care le prelucrăm sunt obținute în mod 
              direct de la dumneavoastră în momentul efectuării programărilor ori al prestării 
              serviciilor medicale solicitate. Însă, există și situații în care vom intra în
               posesia datelor dumneavoastră cu caracter personal prin intermediul altor
                mijloace legale și, totodată, legitime, cum ar fi executarea unui contract 
                la care dumneavoastră nu sunteți parte în mod direct (spre exemplu, contractul 
                încheiat de <span className='span-myclinic' >My Clinic</span>, 
              în calitate de prestator de servicii medicale, cu angajatorul dumneavoastră).
            </p>
            <p>
                <b>În cazul în care beneficiați de servicii de medicina muncii</b>, angajatorul 
              dumneavoastrăne va transmite la momentul încheierii contractului ori la un moment 
              ulterior, datele dumneavoastră cu caracter personal strict necesare prestării 
              serviciilor de medicina muncii, respectiv: nume, prenume, CNP, funcție deținută, 
              detalii legate de riscurile profesionale asociate funcției, date de contact.
            </p>
            <p>
              <b>În cazul în care beneficiați de abonamente medicale oferite de către 
              angajator</b> pentru dumneavoastră și pentru membrii familiei dumneavoastră 
              (soț/soție, copii minori), angajatorul dumneavoastră ne va transmite datele 
              cu caracter personal strict necesare subscrierii abonamentelor medicale, 
              respectiv: nume, prenume, CNP și date de contact.
            </p>
            <p>
              <b>În cazul în care beneficiați de asigurări medicale private</b>, în funcție de 
              contractul încheiat cu fiecare asigurător în parte, asigurătorul dumneavoastră ne 
              va transmite datele cu caracter personal 
              necesare luării în evidență ca având calitatea de asigurat.
            </p>
          </div>
          <div>
            <li><h4>Care sunt scopurile prelucrării datelor cu caracter personal?</h4></li>
            <p>Datele dumneavoastră cu caracter personal sunt prelucrate de 
              către <span className='span-myclinic' >My Clinic</span> în 
              scopuri legitime legate de prestarea serviciilor medicale, 
              conform reglementărilor legale în vigoare, după cum urmează:
            </p>
            <ul>
              <li>
                Încheierea și executarea contractelor de prestare servicii medicale pentru 
                pacientul adult (inclusiv în baza abonamentelor medicale) și servicii medicale 
                de pediatrie, 
                raportare vaccinuri prin înregistrarea pacientului de neonatologie;
              </li>
              <li>
                Efectuarea programărilor în unitățile medicale <span className='span-myclinic' >My Clinic</span> sau prin intermediul 
                serviciului de Call Center, în vederea realizării actului medical conform 
                nevoilor pacienților, a analizelor medicale, în vederea stabilirii diagnosticului, în 
                scopul internării pacienților pentru intervenții/investigații și externare; eliberării
                 de rețete medicale, acordării concediilor medicale, realizării serviciilor de medicina 
                 muncii, de medicină de familie, pentru servicii medicale de pediatrie și raportare
                  vaccinuri, pentru realizarea înregistrării pacientului de neonatologie precum și 
                  alte servicii medicale conform situației specifice fiecărui pacient în parte, 
                  precum și pentru întocmirea și ținerea evidențelor programărilor și a 
                bazei de date a pacienților în sistemele IT (tehnologia informației);
              </li>
              <li>
                Gestionarea sistemelor de comunicații și a sistemelor IT, efectuarea de raporturi de 
                audit, gestionarea securității bazelor de date și a tuturor sistemelor IT;
              </li>
              <li>
                Efectuarea decontării serviciilor către 
                Casele de Asigurări de Sănătate sau de către asiguratorul extern (după caz);
              </li>
              <li>
                Întocmirea documentelor fiscale și încasarea sumelor de plată de la pacienți 
                persoane fizice, inclusiv recuperarea 
                debitelor și a facturilor emise de furnizori și realizarea plății acestora;
              </li>
              <li>
                Raportarea prin corespondență sau prin e-mail către Serviciul de Prevenire a 
                Infecțiilor Asociate Asistenței Medicale (SPIAAM) și către Direcția de 
                Sănătate Publică (DSP), în vederea depistării anumitor infecții ale pacienților, 
                respectiv pentru ținerea registrului electronic 
                unic de monitorizare a infecţiilor asociate asistenţei medicale al unităţii;
              </li>
              <li>
                Soluționarea cererilor și adreselor instituțiilor publice în legătură cu pacienții, 
                în baza unor dispoziții legale imperative, dacă este cazul;
              </li>
              <li>
                Organizarea gărzilor, a raportărilor, realizarea evidenței serviciilor 
                medicale, evidența programărilor în aplicațiile IT, soluționarea 
                reclamațiilor și cererilor primite din partea pacienților sau altor 
                persoane vizate, gestionarea corespondentei uzuale, încheierii și 
                executării contractelor cu policlinici care efectuează examinări pentru 
                șoferii profesioniști și examinarea pacienților, arhivării foilor de 
                observație, arhivarea documentelor medicale pentru radiologie imagistică 
                (RMN, CT, radiografii ș.a.), verificării gradului de ocupare pe săli, 
                întocmirii fișelor de consum, controlului accesului vizitatorilor în 
                saloanele Spitalului Clinic SANADOR, întocmiri de procese-verbale pentru predare-primire 
                bunuri ale pacienților, redactare registru analize pacienți pe secții;
              </li>
              <li>
                Asigurarea pazei bunurilor și a persoanelor în interiorul 
                locațiilor <span className='span-myclinic' >My Clinic</span> prin utilizarea sistemului de supraveghere video;
              </li>
              <li>
                Reprezentarea societății în fața instanțelor de judecată și a autoritatilor publice, 
                realizarea procedurilor de recuperare a creanțelor, după caz;
              </li>
              <li>
                Desfășurarea activităților de recrutare/selecție pentru ocuparea posturilor 
                vacante și gestionarea dosarelor de recrutare/concurs în diferitele etape ale 
                procedurilor de recrutare/selecție, 
                în vederea organizării examenului/concursului de ocupare a postului vacant;
              </li>
              <li>
                Scopurile legate de cercetarea științifică și promovarea soluțiilor medicale 
                inovatoare prin realizarea de fotografii și/sau 
                filmări în cadrul diferitelor intervenții medicale chirurgicale ori non invazive;
              </li>
              <li>
                Desfășurarea activității de promovare către clienți sau potențiali 
                clienți prin newsletter electronic sau sms, în scopuri de marketing direct;
              </li>
              <li>
                Promovarea serviciilor prin utilizarea imaginilor (fotografiilor și/sau filmărilor) 
                pacienților și medicilor 
                (pe website, Facebook, Instagram sau alte mijloace de comunicare online);
              </li>
              <li>
                Scopuri legate de marketing, de promovarea și vânzarea serviciilor oferite 
                de <span className='span-myclinic' >My Clinic</span>, în cadrul evenimentelor organizate 
                de <span className='span-myclinic' >My Clinic</span>, ori la 
                care <span className='span-myclinic' >My Clinic</span> este participant;
              </li>
              <li>
                Eficientizarea serviciilor puse la dispoziția publicului și îmbunătățirea 
                constantă a calității serviciilor oferite, în special a serviciului de Call Center 
                prin înregistrarea apelurilor și a convorbirilor telefonice, efectuate prin 
                intermediul acestui serviciu și prin intermediul formularelor de feedback 
                (inclusiv prin exprimarea 
                opiniei prin intermediul aplicației CLINTELICA ori prin testimonialele transmise);
              </li>
              <li>
                <b>ATENȚIE!</b>  În cazul în care <span className='span-myclinic' >My Clinic</span> va
                 dori să prelucreze datele dumneavoastră 
                cu caracter personal în alte scopuri decât cele declarate inițial, vi se va 
                transmite o notă de informare separată, în care vi se va detalia scopul ulterior 
                al prelucrării, temeiul juridic al prelucrării, precum și perioada de stocare a 
                datelor dumneavoastră cu caracter personal, împreună cu orice alte informații utile 
                în legătură cu prelucrările ulterioare pentru a vă permite să vă exprimaţi 
                consimţământul în mod liber, în cunoștință de cauză și în mod expres pentru 
                fiecare operațiune de prelucrare în parte (dacă prelucrarea 
                respectivă este condiționată de obținerea consimțământului expres al dumneavoastră).
              </li>
            </ul>
          </div>
          <div>
            <li><h4>Care sunt temeiurile juridice ale prelucrării datelor cu caracter personal?</h4></li>
            <ul>
              <li>
                  Efectuarea de demersuri la cererea dumneavoastră înainte de încheierea unui contract 
                (art. 6 alin. (1) lit. b) teza a II-a din RGPD);
              </li>
              <li>
                Executarea contractelor de prestări servicii medicale, 
                la care pacienții sunt parte (art. 6 alin. (1) lit. b) teza I din RGPD);
              </li>
              <li>
                  Îndeplinirea unor obligații legale de către SANADOR (art. 6 alin. (1) lit. c) din RGPD);
              </li>
              <li>
                  Protejarea intereselor dumneavoastră vitale, în situația în care vă aflați, spre exemplu, 
                  într-o situație de urgență medicală sau în vederea 
                  protejării intereselor vitale ale altor persoane fizice (art. 6 alin. (1) lit. d) din RGPD);
              </li>
              <li>
                  Interesul legitim al <span className='span-myclinic' >My Clinic</span> (art. 6 alin. (1) lit. f) din RGPD), cum ar fi: 
                  organizarea întregii activități a <span className='span-myclinic' >My Clinic</span> pentru îndeplinirea obiectului de 
                  activitate; identificarea pacienților în momentul prezentării la recepție 
                  pentru programare sau urgență medicală; organizarea gărzilor și a predării 
                  acestora; evidența serviciilor medicale; evidența programărilor în 
                  aplicațiile IT; soluționarea reclamațiilor și a cererilor primite din partea pacienților 
                  sau altor persoane vizate; controlul accesului vizitatorilor în saloane; demararea și 
                  desfășurarea litigiilor pe rolul instanțelor de judecată și 
                  (eventual) al altor autorități publice; proceduri de recuperare a creanțelor;
              </li>
              <li>
                  Consimțământul dumneavoastră pentru prelucrare, atunci când este acordat expres, liber și 
                  necondiționat, în situații punctuale cum ar fi, 
                  spre exemplu, prelucrările în scop de marketing (art. 6 alin. (1) lit. a) din RGPD).
              </li>
              <p>
                Pentru categoriile speciale de date prelucrate 
                (datele privind sănătatea), temeiurile prelucrării sunt următorele:
              </p>
              <li>
                Scopuri legate de medicina preventivă sau a muncii, de evaluare a capacității de 
                muncă a angajatului, de stabilire a unui diagnostic medical, de furnizare de asistență 
                medicală sau socială sau a unui tratament medical sau de gestionare a sistemelor și 
                serviciilor de sănătate ori de asistență socială (art. 9 alin. (2) lit. h) din RGPD);
              </li>
              <li>
                Motive de interes public în domeniul sănătății publice (art. 9 alin. (2) lit. i) din RGPD);
              </li>
              <li>
                  Protejarea intereselor vitale ale dumneavoastră sau ale unei alte persoane fizice, 
                  atunci când persoana în cauză se află în incapacitate fizică sau 
                  juridică de a-și da consimțământul la prelucrare (art. 9 alin. (2) lit. c) din RGPD);
              </li>
              <li>
                  Constatarea, exercitarea sau apărarea unui 
                  drept în instanță (art. 9 alin. (2) lit. f) din RGPD), în caz de litigiu.
              </li>
            </ul>
            
          </div>
          <div>
            <li><h4>Pentru ce perioadă sunt prelucrate datele cu caracter personal?</h4></li>
            <p>
              În cadrul scopurilor legate de activitatea SANADOR, datele dumneavoastră cu caracter 
              personal vor fi stocate pe o perioadă limitată de timp într-un 
              loc sigur şi în conformitate cu condiţiile şi prevederile legale în domeniu, astfel:
            </p>
            <ul>
              <li>
                Datele dumneavoastră care sunt necesare în scopuri legate de serviciile medicale 
                vor fi stocate pe durata contractului de prestări servicii medicale, respectiv pentru o 
                perioadă de timp rezonabilă, după încetarea contractelor, inclusiv perioada de timp necesară 
                în vederea îndeplinirii obligaţiilor legale prevăzute de legislaţia aplicabilă, constând într-o 
                perioadă generală de 50 de ani, cu excepția cazului în care pentru anumite documente 
                medicale necesare sunt impuse termene de stocare mai mari ori mai scurte;
              </li>
              <li>
                Datele prelucrate în scopuri contabile, în special cele legate de facturare și 
                plăți, vor fi stocate pe o perioadă de 10 ani, cu începere de la data de 1 ianuarie a 
                anului următor încheierii exercițiului financiar în cursul căruia au fost întocmite, 
                conform prevederilor Legii contabilității nr. 82/1991, 
                cu modificările și completările ulterioare, inclusiv cele aduse prin Legea nr. 163/2018;
              </li>
              <li>
                Datele privind supravegherea video pentru asigurarea securității bunurilor și 
                persoanelor se vor stoca pe o perioadă de 30 de zile calendaristice, 
                iar inregistrarea apelurilor telefonice şi a opiniei dumneavoastră privind  
                serviciile <span className='span-myclinic' >My Clinic</span> se vor stoca pe o perioadă de 6 luni, 
                in conformitate cu temeiurile prevazute de legislatia  aplicabila in vigoare.
              </li>
              <li>
                Datele privind procesul de recrutare vor fi păstrate pe o perioadă de 6 luni de la momentrul 
                încheierii procesului de recrutare/selecție pentru postul vacant pentru care ați candidat;
              </li>
              <li>
                Prelucrarea datelor în scop de marketing va avea loc pe durata relației contractuale  
                cu <span className='span-myclinic' >My Clinic</span>, 
                precum și după încetarea acesteia, pentru o perioadă de cel mult 5 ani;
              </li>
              <p>
                <i>
                În situația în veți decide să vă retrageți consimţământul acordat pentru prelucrarea datelor 
                dumneavoastră în scopuri legate de de marketing-ul direct, datele dumneavoastră cu caracter 
                personal nu vor mai fi prelucrate în acest scop, din momentul retragerii consimţământului, 
                fără a afecta însă valabilitatea prelucrării datelor efectuată înainte de acest moment; 
                </i> 
              </p>
              <li>
                De asemenea, datele dumneavoastră cu caracter personal vor fi prelucrate și 
                pe durata existenței unor obligații legale de păstrare a anumitor categorii de 
                date ori de documente justificative, în funcție de reglementările în vigoare, 
                în special în domeniul sănătății publice ori în domeniul fiscal.
              </li>
            </ul>      
          </div>
          <div>
            <li><h4>Căror entități vom divulga datele dumneavoastră cu caracter personal?</h4></li>
            <p>
              În anumite condiții expres reglementate, datele dumneavoastră cu caracter personal 
              pot fi prelucrate de către <span className='span-myclinic' >My Clinic</span>, 
              prin intermediul unor Persoane împuternicite ori 
              în comun cu alte societăți, în acest din urmă caz, fiind vorba despre o relație de tipul 
              operatori asociați, care vor stabili 
              în comun scopurile și mijloacele de prelucrare, conform dispozițiilor art. 26 din RGPD.
            </p>
            <p>
              În unele situații, vor putea avea acces la datele dumneavoastră cu caracter 
              personal furnizorii de servicii cum ar fi, dar fără a se limita la furnizorii de 
              servicii și sisteme IT, diverși partenerii contractuali. De asemenea, 
              se vor putea transmite datele dumneavoastră către avocați, 
              contabili, auditori sau alți 
              profesioniști care au obligația păstrării secretului profesional.
            </p>
            <p>
              În ceea ce privește serviciile de medicina muncii, datele dumneavoastră cu caracter personal vor 
              fi divulgate angajatorului dumneavoastră, însă numai în limitele strict necesare conformării 
              obligațiilor legale, sens în care nu se vor transmite către angajator 
              rezultatele investigațiilor medicale efectuate, ci numai datele cuprinse în fișele de aptitudine.
            </p>
            <p>
              În vederea asigurării serviciilor medicale complete, datele dumneavoastră vor fi 
              furnizate medicilor colaboratori ori clinicilor și 
              laboratoarelor partenere, cu care există încheiate acorduri de confidențialitate.
            </p>
            <p>
              Pentru raportările către autoritățile statului, potrivit obligațiilor legale în vigoare, 
              va fi necesară transmiterea datelor dumneavoastră către diverse instituții publice, cum ar fi, 
              spre exemplu: Casa Națională de Asigurări de Sănătate (CNAS).
            </p>
            <p>
              În cazul în care sunteți asigurat la o companie privată de asigurări de sănătate din 
              România ori din alt stat european, datele dumneavoastră cu caracter personal vor fi 
              transmise către asigurător, 
              în vederea decontării serviciilor medicale de care ați beneficiat.
            </p>
          </div>
          <div>
            <li><h4>Cum și când se realizează transferul datelor dumneavoastră cu caracter personal către țări terțe?</h4></li>
            <p>
              În cazul în care datele dumneavoastră se vor transfera către alte societăți din alte țări, în vederea 
              inițierii, încheierii și dezvoltării unor contracte și/sau proiecte cu o asemenea entitate, precum ar fi, 
              dar fără a se limită la: travel management, 
              veți fi informat și se vor aplica în mod corespunzător garanțiile prevăzute de art. 44-49 din RGPD.
            </p>
          </div>
          <div>
            <li><h4>Care sunt drepturile dumneavoastră în legătură cu prelucrarea datelor cu caracter personal?</h4></li>
            <p>
              În ceea ce privește prelucrarea datelor dumneavoastră cu caracter personal, în temeiul art. 15-22 din RGPD, 
              vă sunt garantate o serie de drepturi:
            </p>
            <ul>
              <li>
                dreptul de acces la date - presupune posibilitatea formulării unei cereri prin care puteți 
                obține confirmarea faptului că vă sunt prelucrate date cu caracter personal și prin care puteți 
                avea acces la categoriile de date prelucrate și informații suplimentare referitoare la prelucrările 
                efectuate, ori, după caz, 
                puteți solicita o copie a datelor cu caracter personal care fac obiectul prelucrării;
              </li>
              <li>
                dreptul la rectificare - puteți obține, fără întârzieri nejustificate, corectarea (rectificarea) 
                datelor inexacte pe care le prelucrăm în legătură cu dumneavoastră;
              </li>
              <li>
                  dreptul la ştergerea datelor (denumit și „dreptul de a fi uitat”, în mediul on-line) 
                  - constă în posibilitatea solicitării și obținerii ștergerii datelor cu caracter personal, 
                  în măsura în care dispozițiile legale în vigoare permit acest lucru;
              </li>
              <li>
                  dreptul la restricţionarea prelucrării - veți avea posibilitatea de a 
                  obținerestricționarea prelucrării, numai în situațiile expres prevăzute de lege;
              </li>
              <li>
                  dreptul la portabilitatea datelor - veți putea primidatele dumneavoastră cu caracter personal 
                  într-un format structurat, 
                  astfel încât să se poată face transferul acestora către un alt operator, în mod automat;
              </li>
              <li>
                  dreptul la opoziție - presupune faptul că vă puteți opune prelucrării datelor dumneavoastră 
                  cu caracter personal în anumite situații și doar în cazul în care prelucrarea în cauză are ca 
                  temei îndeplinirea unei 
                  sarcini de interes public ori se bazează pe interesul legitim al operatorului;
              </li>
              <li>
                dreptul de a nu fi supus unei decizii individuale automatizate - înseamnă ca aveți 
                dreptul de a solicita şi de a obţine retragerea, anularea şi reconsiderarea oricărei 
                decizii care produce efecte juridice asupra dumneavoastră, adoptată exclusiv în baza unei 
                operaţiuni de prelucrare a datelor cu caracter personal prin mijloace automatizate, 
                în scopul evaluării unor trăsături de personalitate, precum abilităţile profesionale, 
                credibilitatea, comportamentul dumneavoastră la locul de muncă, interesele ș.a.m.d. .
              </li>
            </ul>
            <p>
              Nu în ultimul rând, vă aducem la cunoștință faptul că aveți dreptul de a vă adresa 
              Autorității Naționale de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP) 
              sau oricărei instanţe competente în domeniul protecției datelor cu caracter personal.
            </p>
            <p>
              Pentru a vă exercita aceste drepturi, puteţi contacta <span className='span-myclinic' >My Clinic</span> printr-o solicitare scrisă, 
              datată şi semnată, transmisă în atenţia Responsabilului cu protecția datelor cu caracter personal 
              din cadrul SANADOR la adresa de corespondență 
              menționată la pct. 10 .
            </p>
          </div>
          <div>
            <li><h4>Cum pot lua legătura cu Responsabilul cu protecția datelor din cadrul <span className='span-myclinic' >My Clinic</span>?</h4></li>
            <p>
              Adresa de corespondență: Str. Ana Ipătescu, nr.3 , Focsani , Vrancea .
            </p>
          </div>
          <div>
            <li><h4>Ce înțeles au termenii utilizați în cuprinsul prezentei note de informare?</h4></li>
            <p>
              <b>Date cu caracter personal</b> - orice informații în legătură cu o persoană fizică identificată 
              sau identificabilă. Va fi considerată identificabilă acea persoană fizică ce va putea fi 
              identificată în mod direct ori indirect, 
              în special prin utilizarea unui element de identificare, cum ar fi, spre exemplu, un identificator online;
            </p>
            <p>
              <b>Categorii speciale de date cu caracter personal</b> - date cu caracter personal 
              care dezvăluie originea rasială sau etnică, opiniile politice, confesiunea religioasă, 
              convingerile filozofice ori apartenența la sindicate, viața sexuală ori orientarea sexuală, 
              datele privind condamnările penale, date genetice, date biometrice, date privind sănătatea;
            </p>
            <p>
              <b>Date privind sănătatea</b> - datele cu caracter personal legate de sănătatea fizică 
              sau mentală a unei persoane fizice, inclusiv prestarea de servicii de 
              asistență medicală, care dezvăluie informații despre starea de sănătate a acesteia;
            </p>
            <p>
              <b>Consimțământ</b> - orice manifestare de voință liberă, specifică, informată și 
              lipsită de ambiguitate a persoanei vizate prin care aceasta acceptă, printr-o declarație sau printr-o 
              acțiune fără echivoc, ca datele cu caracter personal care o privesc să fie prelucrate;
            </p>
            <p>
              <b>Prelucrarea datelor cu caracter personal</b> - orice operațiune sau set de 
              operațiuni efectuate în legătură cu datele cu caracter personal, cu sau fără 
              utilizarea de mijloace automatizatecum ar fi, spre exemplu, colectarea, înregistrarea, 
              organizarea, structurarea, stocarea, adaptarea, modificarea, extragerea, consultarea, 
              utilizarea, divulgarea prin transmitere, diseminarea sau punerea la 
              dispoziție în orice mod, alinierea sau combinarea, restricționarea, ștergerea sau distrugerea
            </p>
            <p>
              <b>Operator de date cu caracter personal</b> - persoană fizică sau juridică, precum 
              și orice autoritate publică, agenție sau alt organism care, singur ori împreună cu altele 
              stabilește scopurile și mijloacele de prelucrarea a datelor cu caracter personal;
            </p>
            <p>
              <b>Persoană împuternicită</b> - persoana fizică sau juridică, autoritatea publică, agenție sau alt 
              organism care prelucrează datele cu caracter personal în numele operatorului;
            </p>
            <p>
              <b>Persoana vizată</b> - persoana fizică ale cărei date cu caracter 
              personal sunt prelucrate de către operator ori de persoana împuternicită de operator;
            </p>
            <p>
              <b>Țară terță</b> - țară aflată înafara Uniunii Europene ori Spațiului Economic European.
            </p>
          </div>
          
        </ol>
      </Card>
    </Container>
  )
}

export default gdpr