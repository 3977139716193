import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "bootswatch/dist/zephyr/bootstrap.min.css";
import "./stylesheets/globalStyle.css";

import {
  Navigation,
  OfertaMM,
  PrimAjutorMM,
  ScopulExamenului,
  Home,
  InfoMM,
  Inscriere,
  DeCeSaViiLaNoi,
  DocumenteNecesareAsiguratilor,
  DrepturileSiObligastiilePacientilor,
  Psihoterapie,
  PsihologiaMuncii,
  PsihologiaTransporturilor,
  AvizPsihologicArma,
  ArticoleUtile,
  ConsultatiiOnline,
  Contact,
  SolicitaDieta,
  GDPR,
  Cookies,
} from "./views/pages";
import Footer from "./views/components/Footer";
import Header from "./views/components/Header";
import { Container } from "react-bootstrap";

function App() {
  return (
    <>
      <Router>
        <div>
          <Navigation />
          <Header />
          <main>
            <Container>
              {/* noi rute grupate pe categorie */}
              <Routes>
                <Route path="/" element={<Home />} exact />
                <Route path="/medicina-muncii">
                  <Route path="info" element={<InfoMM />} exact />
                  <Route path="oferta" element={<OfertaMM />} exact />
                  <Route path="prim-ajutor" element={<PrimAjutorMM exact />} />
                  <Route
                    path="scopul-examenului"
                    element={<ScopulExamenului exact />}
                  />
                </Route>
                <Route path="/medicina-de-famillie">
                  <Route path="inscriere" element={<Inscriere />} exact />
                  <Route
                    path="deCeSaViiLaNoi"
                    element={<DeCeSaViiLaNoi />}
                    exact
                  />

                  <Route
                    path="documente-necesare-asiguratilor"
                    element={<DocumenteNecesareAsiguratilor />}
                    exact
                  />
                  <Route
                    path="drepturile-si-obligatiile-pacientilor"
                    element={<DrepturileSiObligastiilePacientilor />}
                    exact
                  />
                </Route>
                <Route path="/psihologie">
                  <Route path="psihoterapie" element={<Psihoterapie />} exact />
                  <Route
                    path="psihologia-muncii"
                    element={<PsihologiaMuncii />}
                    exact
                  />
                  <Route
                    path="psihologia-transporturilor"
                    element={<PsihologiaTransporturilor />}
                    exact
                  />
                  <Route
                    path="aviz-psihologic-arma"
                    element={<AvizPsihologicArma />}
                    exact
                  />
                </Route>
                <Route path="/informatii-pacienti">
                  <Route
                    path="articole-utile"
                    element={<ArticoleUtile />}
                    exact
                  />
                  <Route
                    path="consultatii-online"
                    element={<ConsultatiiOnline />}
                    exact
                  />
                  <Route
                    path="solicita-dieta"
                    element={<SolicitaDieta />}
                    exact
                  />
                </Route>
                <Route path="/contact" element={<Contact />} exact />
                <Route path="/footer">
                  <Route path="gdpr" element={<GDPR />} />
                  <Route path="cookies" element={<Cookies />} />
                </Route>
              </Routes>
            </Container>
          </main>

          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
