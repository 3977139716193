import React from "react";
import { useState } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleSelect = (eventKey) => {
    closeMenu();
  };

  return (
    <>
      <Navbar
        expand="lg"
        className="navigation sticky-top"
        expanded={isMenuOpen}
        onSelect={handleSelect}
      >
        <Container>
          <Navbar.Brand as={Link} to={"/"} onClick={closeMenu}>
            <img className="logo" src={logo} alt="img" as={Link} to="/" />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="dropdown-autoclose-true"
            onClick={toggleMenu}
            data-bs-toggle="collapse"
          />
          <Navbar.Collapse
            id="dropdown-autoclose-true"
            data-bs-parent=".navigation"
          >
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/" onClick={closeMenu}>
                Acasa
              </Nav.Link>

              <NavDropdown
                title="Medicina muncii"
                id="collapsible-nav-dropdown"
                renderMenuOnMount={true}
                menuRole="menu"
                rootCloseEvent="click"
                onSelect={handleSelect}
              >
                <NavDropdown.Item
                  as={Link}
                  to={"/medicina-muncii/oferta"}
                  onClick={closeMenu}
                >
                  Oferta noastră
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={"/medicina-muncii/scopul-examenului"}
                  onClick={closeMenu}
                >
                  Scopul examenului medical
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={"/medicina-muncii/info"}
                  onClick={closeMenu}
                >
                  Solicită informații
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={"/medicina-muncii/prim-ajutor"}
                  onClick={closeMenu}
                >
                  Curs de Prim Ajutor
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Medicină de familie"
                id="collapsible-nav-dropdown"
                renderMenuOnMount={true}
                menuRole="menu"
                rootCloseEvent="click"
                onSelect={handleSelect}
              >
                <NavDropdown.Item
                  as={Link}
                  to={"/medicina-de-famillie/deCeSaViiLaNoi"}
                  onClick={closeMenu}
                >
                  De ce să vii la noi?
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={"/medicina-de-famillie/inscriere"}
                  onClick={closeMenu}
                >
                  Cum mă inscriu?
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to={"/medicina-de-famillie/documente-necesare-asiguratilor"}
                  onClick={closeMenu}
                >
                  Documente necesare asiguraților
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={
                    "/medicina-de-famillie/drepturile-si-obligatiile-pacientilor"
                  }
                  onClick={closeMenu}
                >
                  Drepturile și obligațiile pacienților
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Psihologie - Psihoterapie"
                id="collapsible-nav-dropdown"
                renderMenuOnMount={true}
                menuRole="menu"
                rootCloseEvent="click"
                onSelect={handleSelect}
              >
                <NavDropdown.Item
                  as={Link}
                  to={"/psihologie/psihoterapie"}
                  onClick={closeMenu}
                >
                  Psihoterapie
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={"/psihologie/psihologia-muncii"}
                  onClick={closeMenu}
                >
                  Psihologia muncii si organizațională
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={"/psihologie/psihologia-transporturilor"}
                  onClick={closeMenu}
                >
                  Psihologia transporturilor categoria B
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={"/psihologie/aviz-psihologic-arma"}
                  onClick={closeMenu}
                >
                  Aviz psihologic pentru armă
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Informații"
                id="collapsible-nav-dropdown"
                renderMenuOnMount={true}
                menuRole="menu"
                rootCloseEvent="click"
                onSelect={handleSelect}
              >
                <NavDropdown.Item
                  as={Link}
                  to={"/informatii-pacienti/articole-utile"}
                  onClick={closeMenu}
                >
                  Articole utile
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={"/informatii-pacienti/consultatii-online"}
                  onClick={closeMenu}
                >
                  Consulatiții online
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={"/informatii-pacienti/solicita-dieta"}
                  onClick={closeMenu}
                >
                  Solicită dietă
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <Nav.Link as={Link} to={"/contact"} onClick={closeMenu}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Navigation;
