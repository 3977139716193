import React from "react";
import { Card, Container, Image } from "react-bootstrap";
import imgMM1 from "../../../images/madicina-muncii/2_scopul_examenului_5.jpg";
import imgMM2 from "../../../images/Poze_My_clinic/SalaMedicinaMuncii.jpg";
import imgMM3 from "../../../images/madicina-muncii/2_scopul_examenului2.jpg";
import imgMM4 from "../../../images/madicina-muncii/2_scopul_examenului3.jpg";

const ScopulExamenului = () => {
  return (
    <Container>
      <h1 className="mt-4 mb-4">Scopul examenului medical</h1>
      <Card className="box-shadow-left p-4 mb-4">
        <h4>Noi Avem Grijă de Sănătatea Angajaților Dumneavoastră!</h4>
        <h5>Efectuarea examenului medical are urmatoarele scopuri:</h5>
        <ul>
          <li>
            confirmarea sau infirmarea la perioade de timp stabilite a
            aptitudinii în muncă pentru profesia, funcția și locul de muncă.
          </li>
          <li>
            depistarea apariției unor boli care constituie contraindicații
            pentru activitățile și locurile de muncă cu expunere la factori de
            risc profesional(eventual alcoolism nedeclarat).
          </li>
          <li>diagnosticarea bolilor profesionale sau legate de profesie.</li>
          <li>
            depistarea bolilor care constituie risc pentru viață și sănătatea
            celorlalți lucrători la același loc de muncă.
          </li>
          <li>
            depistarea bolilor care constituie risc pentru securitatea unității,
            pentru calitatea produselor sau pentru populația cu care lucrătorul
            vine în contact prin natura activității sale.
          </li>
        </ul>

        <p>
          Supravegherea medicală se realizează prin examinări clinice, teste
          psihologice, analize medicale și investigații (EKG, visiotest,
          audiometrie sau spirometrie).
        </p>
      </Card>

      <div className="d-flex flex-wrap justify-content-center">
        <Image src={imgMM1} className="image-container" />
        <Image src={imgMM2} className="image-container" />
        <Image src={imgMM3} className="image-container" />
        <Image src={imgMM4} className="image-container" />
      </div>
    </Container>
  );
};

export default ScopulExamenului;
