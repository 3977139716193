import React from 'react'
import { Card, Container , Image ,Row , Col} from 'react-bootstrap'
import dietaImg from '../../../images/custom/cere-dieta.jpg'

const SolicitaDieta = () => {
  return (
    <Container>
      <Card className='box-shadow-left m-3 p-3'>
        <h1 className='m-3 p-3'>Solicită dietă</h1>
        <h5>Ești ceea ce mănânci!</h5>
        <p>
          Ce mănânci în fiecare zi are un efect important asupra performanțelor tale. 
          Mulți oameni știu că alimentele pe care le consumă le pot afecta sănătatea, 
          dar mai puțini știu că există o legătură directă între regimul nostru alimentar 
          și felul în care gândim, ne simțim sau acționăm. O dietă bună este o parte 
          importantă a unui stil de viață sănătos dar toți realizăm cât de greu 
          este să punem în farfurie alimente sănătoase și delicioase în același timp.
        </p>
        <p>
          Ineficientă unei diete, abandonul și reluarea obiceiurilor nesănătoase se 
          datorează în cea mai mare parte motivației scăzute pentru schimbare și nu 
          regimului ales. Înainte de a începe va recomand să va sfătuiți cu medicul 
          dumneavoastră pentru a stabili gravitatea situației datorată greutății sau 
          bolilor dar și impactul psihic al acesteia. Cele două aspecte se susțîn unul 
          pe altul în timpul creșterii în greutate (mâncăm din cauza stresului), 
          dar și în timpul dietei (dacă suntem motivați - slăbim mai mult). 
          Succesul și eșecul curelor de slăbire este în noi și 
          depinde de voință fiecăruia de a elimina riscurile asupra sănătății noastre.
        </p>
        <p>
          Fiecare om are motivele sale personale pentru a ține un regim și se află într-un 
          anumit punct pe scară începerii unei cure. 
          Împreună putem găși o dietă personalizată pentru a obține rezultatele pozitive.
        </p>
        <p>
          Aștept solicitările dumneavoastră 
          la email office@my-clinic.ro și pe adresa “My Clinic”, Focșani, str. Ana Ipatescu, nr.2.
        </p>
        <p>Va oferim diete și sfaturi medicale pentru următoarele afecțiuni:</p>
        

        <Row>
          <Col>
            <ul>
              <li>Anemie</li>
              <li>Colesterol crescut</li>
              <li>Dislipidemie</li>
              <li>Diabet zaharat</li>
              <li>Boală ulceroasă</li>
              <li>Afecțiuni hepatobiliare</li>
              <li>Hepatită virală</li>
              <li>Ciroză</li>
              <li>Intestin iritabil</li>
              <li>Enterocolită acută</li>
              <li>Gastroduodenita acută</li>
              <li>Constipație</li>
              <li>Sindrom diareic</li>
              <li>Hipertensiune arterială</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>Hemoroizi</li>
              <li>Varice</li>
              <li>Litiaza renală</li>
              <li>Insuficientă renală</li>
              <li>Bolile prostatei</li>
              <li>Lombociatica</li>
              <li>Alergii/ urticarie</li>
              <li>Sfaturi despre răceală</li>
              <li>Alimentația copiilor mici</li>
              <li>Diversificarea alimentației sugarului</li>
              <li>Cum să mâncăm sănătos</li>
              <li>Comparația caloriilor</li>
              <li>Regim de slăbire natural</li>
            </ul>
          </Col>
          <Col>
            <Image src={dietaImg} />
          </Col>
        </Row>

      </Card>
    </Container>
  )
}

export default SolicitaDieta