import React from "react";
import { Nav, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  const stopClickPropagation = (event) => event.stopPropagation();

  return (
    <div expand="lg" className="footer d-flex">
      <Container>
        <Row>
          <Col xs={3} md={4}>
            <Nav.Link as={Link} to="/" onClick={stopClickPropagation}>
              ACASA
            </Nav.Link>
          </Col>
          <Col xs={3} md={4}>
            <Nav.Link
              as={Link}
              to="/footer/gdpr"
              onClick={stopClickPropagation}
            >
              GDPR
            </Nav.Link>
          </Col>
          <Col xs={3} md={4}>
            <Nav.Link as={Link} to="/contact" onClick={stopClickPropagation}>
              CONTACT
            </Nav.Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
