import React from 'react'
import { Container , Card , Image } from 'react-bootstrap'
import documenteImg from '../../../images/medicina-de-familie/5_drepturi_pacienti.jpg'

const DrepturileSiObligastiilePacientilor = () => {
  return (
    <Container>
      <Card className='box-shadow-left m-3 p-3'>
        <h1 className='m-3 p-3'>Drepturile și obligațiile pacienților</h1>
        <h5>În cadrul sistemului de asigurări sociale de sănătate asiguratii au următoarele drepturi:</h5>
        <div>
          <Image src={documenteImg}  className='image-container-right'/>
          <ol>
            <li>să aleagă furnizorul de servicii medicale, precum și casă de asigurări de sănătate la care se asigura, 
              în condițiile prezenței legi și a contractului-cadru;
            </li>
            <li>
              să fie înscriși pe lista unui medic de familie pe care îl solicită, dacă îndeplinesc toate condițiile 
              prezenței legi, 
              suportând cheltuielile de transport dacă opțiunea este pentru un medic din altă localitate;
            </li>
            <li>
              să își schimbe medicul de familie ales numai după expirarea a cel puțîn 6 luni 
              de la dată înscrierii pe listele acestuia;
            </li>
            <li>
              să beneficieze de servicii medicale, medicamente, 
              materiale sanitare și dispozitive medicale în mod nediscriminatoriu, în condițiile legii;
            </li>
            <li>
              să efectueze controale profilactice, în condițiile stabilite prin contractul-cadru;
            </li>
            <li>
              să beneficieze de servicii de asistență medicală preventivă și de promovare a 
              sănătății, inclusiv pentru depistarea precoce a bolilor;
            </li>
            <li>
              să beneficieze de servicii medicale în ambulatorii și în spitale 
              aflate în relație contractuală cu casele de asigurări de sănătate;
            </li>
            <li>
              să beneficieze de servicii medicale de urgență
            </li>
            <li>
              să beneficieze de unele servicii de asistență stomatologică;
            </li>
            <li>
              să beneficieze de tratament fizioterapeutic și de recuperare;
            </li>
            <li>
              să beneficieze de tratament fizioterapeutic și de recuperare;
            </li>
            <li>
              să beneficieze de dispozitive medicale;
            </li>
            <li>
              să beneficieze de servicii de îngrijiri medicale la domiciliu;
            </li>
            <li>
              să li se garanteze confidențialitatea privind datele, 
              în special în ceea ce privește diagnosticul și tratamentul;
            </li>
            <li>
              să aibă dreptul la informație în cazul tratamentelor medicale;
            </li>
            <li>
              să beneficieze de concedii și indemnizații de asigurări sociale de sănătate în condițiile legii.
            </li>
          </ol>
          <h5>Obligațiile asiguraților pentru a putea beneficia de aceste drepturi sunt următoarele:</h5>
          <ol>
            <li>
              să se înscrie pe lista unui medic de familie;
            </li>
            <li>
              să anunțe medicul de familie ori de câte ori apar modificări în starea lor de sănătate;
            </li>
            <li>
              să se prezinte la controalele profilactice și periodice stabilite prin contractul-cadru;
            </li>
            <li>
              să anunțe în termen de 15 zile medicul de familie și casă de asigurări asupra modificărilor 
              datelor de identitate sau 
              modificărilor referitoare la încadrarea lor într-o anumită categorie de asigurați;
            </li>
            <li>
              să respecte cu strictețe tratamentul și indicațiile medicului;
            </li>
            <li>
              să aibă o conduită civilizată față de personalul medico-sanitar;
            </li>
            <li>
              să achite contribuția datorată fondului și suma reprezentând coplata, 
              în condițiile stabilite prin contractul-cadru;
            </li>
            <li>
              să prezinte furnizorilor de servicii medicale documentele justificative ce atestă calitatea de asigurat.
            </li>
          </ol>
          <p>
            Persoanele care nu fac dovadă calității de asigurat beneficiază de servicii medicale 
            numai în cazul urgențelor medico-chirurgicale și al bolilor cu potențial endemo-epidemic 
            și cele prevăzute în Programul național de imunizari, monitorizarea evoluției sarcinii și 
            a lauzei, servicii de planificare familială în condițiile art. 223, 
            în cadrul unui pachet minimal de servicii medicale, stabilit prin contractul-cadru. 
          </p>
          <p>
            Tarifele serviciilor hoteliere pentru persoană care însoțește copilul internat în 
            vârstă de până la 3 ani, precum și pentru însoțitorul persoanei cu handicap grav 
            internate se suportă de către casele de asigurări, 
            dacă medicul consideră necesară prezența lor pentru o perioada determinată.
          </p>
        </div>
      </Card>
    </Container>

  )
}

export default DrepturileSiObligastiilePacientilor