import React from "react";
import MapGog from "../components/MapGog";
import Card from "react-bootstrap/Card";

const Contact = () => {
  return (
    <>
      <h1 className="m-4">Contact</h1>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>
            Ne puteți găsi la adresa: Str. Ana Ipătescu nr.3 , Focsani.
          </Card.Title>
          <Card.Title>Medicina muncii</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            0766 911 567
          </Card.Subtitle>
          <Card.Title>Psihologie</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            0766 911 567
          </Card.Subtitle>

          <Card.Title>Medicină de familie</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            0762 734 407
          </Card.Subtitle>
        </Card.Body>
      </Card>

      <MapGog />
    </>
  );
};

export default Contact;
