import React from 'react'
import { Card, Container , Image } from 'react-bootstrap'
import armaImg from '../../../images/Psihologie/4_aviz_psiho_arma.jpg'

const AvizPsihologicArma = () => {
  return (

    <Container>
      <Card className='box-shadow-left m-3 p-3'>
        <h1 className='m-3 p-3'>Aviz port armă </h1>
        <h5>Serviciile psihologice în domeniul securității naționale, apărării și ordinii publice includ:</h5>
        <div>
          <Image src={armaImg} className='image-container-right'/>
          <ol>
            <li>
              Testare psihologică  pentru obținere aviz psihologic permis port-armă pentru agenții de pază și ordine;
            </li>
            <li>
              Examen psihologic pentru obținere aviz psihologic 
              port-armă pentru tir sportiv (amatori și profesioniști), profesori de tir;
            </li>
            <li>
              Evaluare psihologică pentru obținere aviz psihologic permis port-armă 
              pentru vânători amatori și profesioniști (dețînătorii de arme de vânătoare);
            </li>
            <li>
              Test psihologic obținere aviz psihologic permis port-armă pentru polițiștii comunitari;
            </li>
            <li>
              Testare psihologică obținere aviz port armă pentru persoanele fizice dețînătoare de arme pentru autoapărare
            </li>
            <li>
              Evaluare psihologică pentru aviz permis port armă pentru angajați și administrator ai 
              firmelor care comercializează, transporta arme și 
              muniții și a celor care execută servicii de montaj și întreținere a sistemelor de pază și alarmare.
            </li>
          </ol>
          <p>
            Evaluările psihologice din domeniul psihologiei securității naționale se desfășoară conform unei metodologii specifice, în funcție de scopul evaluării 
            și se finalizează cu eliberarea avizului psihologic permis portarmă.
          </p>
        </div>
      </Card>
    </Container>

  )
}

export default AvizPsihologicArma