import React from 'react'
import { Container ,Image , Card } from 'react-bootstrap'
import ofertaImg from '../../../images/madicina-muncii/1_oferta_noastra.jpg'

const OfertaMM = () => {
  return (
    <Container>
      <h1 className='m-4'>Oferta noastră</h1>
      <div className='image-container-center'>
        <Image src={ofertaImg} alt="group of people" />
      </div>
      <Card className='box-shadow-left mt-3 p-5 pt-1 '>
      <p style={{marginTop:"2rem"}}> <span className='span-myclinic' >My Clinic</span> își propune să vă ofere asistență 
        medicală de cea mai bună calitate, dar în primul rând să vă menținem sănătoși.</p>
      <p>
        Ne bazăm pe stabilirea unei relații strânse între medic și angajații dumneavoastră, 
        acesta devenind managerul stării lor de sănătate atât la locul de muncă cât și acasă.
      </p>
      <p>
        Alegând <span className='span-myclinic'>My Clinic</span> veți observa un stil nou de abordare a nevoilor dumneavoastră. 
        Ne bazăm pe calitatea și eficiența actului medical prestat, 
        ambianța plăcută și vă oferim opțiunile unui mod de muncă (viață) sănătos și acasă
      </p>
      <p>
        Planul standard cuprinde servicii specifice de medicina muncii in conformitate cu 
        legislația în vigoare (<b>Legea 319/2006</b> privind securitatea și sănătatea în muncă 
        și <b>H.G. 355/2007</b>  privind supravegherea sănătății lucrătorilor).Scopul activității 
        serviciului de medicină muncii este predominant preventiv, el evaluează riscurile 
        privind îmbolnăvirile profesionale și oferă angajaților soluții pentru evitarea apariției 
        bolilor profesionale sau a celor legate de profesie.
      </p>
      <p>
        În cadrul controlului de medicină muncii se realizează un complex de examinări medicale 
        clinice, biologice și 
        paraclinice conform normelor stipulate prin lege pentru fiecare tip de profesie.
      </p>
      <p>
        Consultatiile pot avea loc la sediul dumneavoastra.
       Va garantam ca investigatiile si consultatiile se fac prompt si competent.
      </p>
      <p>
        <b>Avantajele principale pe care le obțineți sunt:</b>
      </p>
      <ul>
        <li>Menținerea unui climat sănătos la locul de muncă</li>
        <li>Limitarea absențelor de la program</li>
        <li>Motivarea personalului prin asigurarea de analize și investigații suplimentare.</li>
        <li>Respectarea legislației la controalele autorităților - ITM, DSP, DSV, etc...</li>
      </ul>
      <p>
        Vă dorim să descoperiți siguranță și o viață de calitate ca parteneri <span className='span-myclinic'>My Clinic</span>!
      </p>
      </Card>
    <div style={{marginBottom:"4rem"}}> </div>
    </Container>
  )
}

export default OfertaMM