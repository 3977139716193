import { GoogleMap, Marker } from "@react-google-maps/api";
import { useMemo } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MapGog = () => {
  const position = useMemo(() => ({ lat: 45.703821, lng: 27.18699 }), []);
  const zoom = 17;
  return (
    <div style={{ border: "1px solid" }}>
      <Wrapper apiKey={MAP_API_KEY}>
        <GoogleMap
          center={position}
          zoom={zoom}
          mapContainerClassName="map-container"
        >
          <Marker position={position} title="My Clinic" label="My Clinic" />
        </GoogleMap>
      </Wrapper>
    </div>
  );
};

export default MapGog;
