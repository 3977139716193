import React from 'react'
import { Container , Image} from 'react-bootstrap'
import listImg from '../../../images/madicina-muncii/3_solicita_informatii2.gif'

const InfoMM = () => {
  return (
    <>
      <Container>
        <h1 className='m-4'>Solicită Informații</h1>

        <h4>Va dorim să descoperiți siguranță și o viață de calitate!</h4>
       
          
          <Image className='image-container-right' src={listImg} />
      
        <p>
          Cadrul legislativ național (Legea <b>319/2006</b> privind securitatea și sănătatea în 
          muncă și <b>H.G. 355/2007</b> privind supravegherea sănătății lucrătorilor, cu modificările 
          ulterioare) oferă dreptul și 
          obligativitatea la servicii de medicină muncii prin sarcina exclusivă a angajatorului.
        </p>
        <p>
          Având o experiență de 8 ani în piață serviciilor de medicina muncii, beneficiați 
         <b> promp și competent</b> de monitorizarea medicală a angajaților.
        </p>
        <p>
          La noi gășiți o atmosfera caldă și un <b>personal cu experientă</b> în cele mai bune firme locale și 
          cu deschideri occidentale privind respectul și atitudinea față de pacient.
        </p>
        <p>
          Echipa noastră medicală, cu toată aparatură necesară activității se poate deplasa la sediul 
          dumneavoastră, scutindu-va de drumul la spital sau centru medical.
        </p>
        <p>
          Tarifele sunt negociabile în funcție de numărul de examinări, structura personalului și 
          riscurile specifice privind sănătatea ale firmei.
        </p>
      </Container>
    </>
  )
}

export default InfoMM