import React from 'react'
import { Container ,Card , Image} from 'react-bootstrap'
import primAjutorImg from '../../../images/madicina-muncii/4_curs_prim_ajutor2.jpg'
import primAjutorImg2 from '../../../images/madicina-muncii/4_curs_prim_ajutor.jpg'

const PrimAjutorMM = () => {
  return (
    <>
      <Container >
        <h1 className='m-3 p-3'>Curs de Prim Ajutor</h1>
        <Card className='box-shadow-left mb-4 p-4 clearfix' >
          <div className='mb-3'>
          <h4 className='m-4'>Rezumat curs de prim ajutor</h4>
          
            <Image src={primAjutorImg} className='image-container-right'/>
          <p>
            Primul ajutor în caz de accidentare sau de îmbolnăvire acută trebuie 
            să fie acordat la locul unde s-au produs acestea, 
            de către orice p+ersoană care este pregătită pentru astfel de intervenții.
          </p>
          <br />
            <h5>Ce este un salvator?</h5>
            <p>
              Cel care acordă primul ajutor.  Salvatorul nu înlocuiește medicul, dar prin 
              măsurile pe care le aplică, el trebuie să reușească să evite:
            </p>
            <ul>
              <li>
                înrăutățirea stării accidentatului;
              </li>
              <li>
                apariția altor complicații;
              </li>
              <li>
                producerea morții victimei.
              </li>
            </ul>
          </div>
          <div className='mb-3'>
            <p>
              În funcție de pregătirea lor, salvatorii pot fi încadrați în trei categorii:
            </p>
            <ul>
              <li>
                 medicii de orice specialitate: ei vor interveni cu prioritate la locul unui accident;
              </li>
              <li>
                 cadrele medii sanitare și studenții mediciniști din ultimii ani de facultate;
              </li>
              <li>
                 toate persoanele care au fost instruite pentru a acordă primul ajutor.
              </li>
            </ul>
          </div>
          <div className='mb-3'>
            <p>
              Cu excepția cazurilor de mare urgență și/sau petrecute în locuri izolate, 
              primul ajutor în caz de accident sau îmbolnăvire acută ar trebui să fie 
              acordat de către salvatorii din prima și a două categorie  salvatorii 
              profesioniști.
              Salvatorul trebuie să cunoască riscurile specifice locurilor de muncă pentru care 
              este pregătit să acorde prim ajutor în caz de accident sau îmbolnăvire acută, având 
              și un rol preventiv. El trebuie să fie în legătură cu personalul de conducere și 
              cu personalul implicat în activitatea de securitate și sănătate în muncă.
            </p>
            <p>
              Formarea salvatorului trebuie să fie predominant practică, explicațiile 
              fiind date în timpul predării și învățării manevrelor. Este necesar că 
              această pregătire să fie completată cu antrenamente periodice, pentru că 
              salvatorii să 
              poată efectua manevrele de reanimare și operațiile de salvare oricând și oriunde.
            </p>
            <p>
              Obligația cea mai importantă a unui salvator este să prevină agravarea 
              stării victimei, deoarece acordarea unor îngrijiri necorespunzătoare 
              poate complică sau chiar 
              compromite intervențiile ulterioare de specialitate ale medicului.
            </p>
          </div>
          <div className='mb-3'>
            <h4>Care sunt acțiunile salvatorului?</h4>
            <br />
              <h5>
                Acțiunile salvatorului depind de starea victimei, astfel:
              </h5>
              <Image src={primAjutorImg2} className='image-container-right'/>
              <ol>
                <li className='mt-3'>
                  Dacă victima nu vorbește (este inconștiență), dar respiră și îi bate 
                inima (are puls), sunt necesare următoarele acțiuni:
                </li>
                <ul>
                  <li>
                    așezarea în poziție de siguranță;
                  </li>
                  <li>
                    acoperirea victimei, alarma;
                  </li>
                  <li>
                    supravegherea circulației, a stării de conștientă, 
                    a respirației, până la sosirea ajutoarelor medicale.
                  </li>
                </ul>
                <li className='mt-3'>
                  Dacă victima nu răspunde, nu respiră, dar are puls, sunt necesare următoarele acțiuni:
                </li>
                <ul>
                  <li>
                    degajarea (eliberarea) căilor respiratorii;
                  </li>
                  <li>
                    manevra Heimlich;
                  </li>
                  <li>
                    respirație "gură la gură" sau "gură la nas".
                  </li>
                </ul>
                <li className='mt-3'>
                  Dacă victima nu răspunde, nu respiră, dar are puls, sunt necesare următoarele acțiuni:
                </li>
                <ul>
                  <li>
                    reanimarea cardio-respiratorie (masaj cardiac extern asociat 
                    cu respirație "gură la gură" sau "gură la nas").
                  </li>
                </ul>
                <li className='mt-3'>
                  Dacă victima sângerează abundent, sunt necesare următoarele acțiuni:
                </li>
                <ul>
                  <li>
                      compresie manuală locală;
                  </li>
                  <li>
                      pansament compresiv;
                  </li>
                  <li>
                      compresie manuală la distanță (în zona subclaviculara sau inghinală).
                  </li>
                </ul>
                <li className='mt-3'>
                  Dacă victima prezintă arsuri provocate de foc sau căldură:
                </li>
                <ul>
                  <li>
                    se face spălare cu apă pentru a evita că arsură să progreseze și pentru răcorire.
                  </li>
                </ul>
                <li className='mt-3'>
                  Dacă victima prezintă arsuri provocate de substanțe chimice:
                </li>
                <ul>
                  <li>
                    se face spălare abundentă cu apă. 
                    Nu se încearcă neutralizarea acidului cu baza și invers.
                  </li>
                </ul>
                <li className='mt-3'>
                  Dacă victima vorbește, dar nu poate efectua anumite mișcări:
                </li>
                <ul>
                  <li>
                    oricare ar fi semnele, se va acționa că și cum victima ar avea o fractură, 
                    evitând să o deplaseze și respectând toate eventualele deformări la nivelul: 
                    membrului superior, membrului inferior, coloanei vertebrale.
                  </li>
                </ul>
                <li className='mt-3'>
                  Dacă victima prezintă plăgi simple:
                </li>
                <ul>
                  <li>
                    se curată și se pansează plagă.
                  </li>
                </ul>
                <li className='mt-3'>
                  Dacă victima prezintă plăgi grave:
                </li>
                <ul>
                  <li>
                    se așează victima într-o poziție adecvată și se îngrijește plagă, 
                    aplicandu-se comprese pentru oprirea sângerării.
                  </li>
                </ul>
              </ol>
          </div>
         

        </Card>
      </Container>
    </>
  )
}

export default PrimAjutorMM