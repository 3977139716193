import React from "react";
import { Card, Container, Image } from "react-bootstrap";
import psihologiaTransportImg from "../../../images/Psihologie/3_psiho_transport.jpg";

const PsihologiaTransporturilor = () => {
  return (
    <Container>
      <Card className="box-shadow-left m-3 p-3">
        <h1 className="m-3 p-3">Psihologia Transporturilor</h1>
        <h5>Cabinetul nostru psihologic este autorizat:</h5>
        <div>
          <Image
            src={psihologiaTransportImg}
            className="image-container-right"
          />
          <ol>
            <li>
              să evalueze psihologic în domeniul transporturilor candidații
              pentru școlarizarea în conducerea autovehiculelor, pentru
              categoria de permis B să asiste și să consilieze psihologic
              candidații la școlarizarea în conducerea autovehiculelor, inclusiv
              la redobândirea permisului de conducere, pentru alte categorii
              decât profesioniștii;
            </li>
            <li>
              să asiste și să consilieze psihologic candidații la școlarizarea
              în conducerea autovehiculelor, inclusiv la redobândirea permisului
              de conducere, pentru alte categorii decât profesioniștii;
            </li>
            <li>evaluarea contextului muncii în domeniul transporturilor.</li>
          </ol>
        </div>
      </Card>
    </Container>
  );
};

export default PsihologiaTransporturilor;
