import React from "react";

const Header = () => {
  return (
    <>
      <div className="motto">
        <header className="motto-header">
          <h1>Scopul nostru este sănătatea ta!</h1>
        </header>
      </div>
    </>
  );
};

export default Header;
