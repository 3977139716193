import React from 'react'
import { Card, Container , Image } from 'react-bootstrap'
import psihologiaMunciiImg2 from '../../../images/Psihologie/2-psihologia-muncii2.jpg'

const PsihologiaMuncii = () => {
  return (
    <Container >
      <Card className='box-shadow-left m-3 p-3'>
        <h1 className='m-3 p-3'>Psihologia Muncii</h1>
        <div>
          <Image src={psihologiaMunciiImg2} className='image-container-right'/>
          <p>
            Psihologia muncii studiază fenomenele și particularitățile 
            psihice implicate în activitatea de muncă a omului precum și 
            comportamentul acestuia la locul de muncă.
          </p>
            <h6>Obiectul psihologiei muncii îl constituie în linii generale:</h6>
          <ul>
            <li>componentele psihice ale diferitelor activități: bagajul de cunoștințe 
              și deprinderi, aptitudinile, înclinațiile și interesele;
            </li>
            <li>
              factorii psihologici care contribuie la sporirea productivității muncii: 
              atenția și spiritul de observație, gândirea, memoria, inteligență;
            </li>
            <li>
              afectivitatea, motivația și voință.
            </li>
          </ul>
          <h6>Scopul nostru este:</h6>
          <ul>
            <li>
              Ameliorarea gradului de securitate a muncii
            </li>
            <li>
              Reducerea absenteismului
            </li>
            <li>
              Diminuarea gradului de solicitare
            </li>
            <li>Diminuarea gradului de solicitare</li>
            <li>Creșterea satisfacției în muncă</li>
          </ul>

        </div>

      </Card>
    </Container>
  )
}

export default PsihologiaMuncii