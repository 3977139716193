import React from "react";
import { Container, Card, Image } from "react-bootstrap";
import inscriere_img from "../../../images/home-images/sala_asteptare.jpg";

const Inscriere = () => {
  return (
    <Container>
      <Card className="box-shadow-left m-4 p-3">
        <h1>Cum mă înscriu?</h1>
        <div>
          <Image src={inscriere_img} className="image-container-right" />
          <p>
            Fie că te muți într-o altă localitate, fie că nu îți place modul în
            care eșți tratat de actualul doctor, trebuie să știi că poți opta
            oricând pentru un nou medic de familie. Transferul nu este deloc
            dificil și nici nu-ți va cauza prea multe bătăi de cap.
          </p>
          <p>
            Primul lucru pe care trebuie sail faci este să depui o cerere de
            înscriere prin transfer la medicul de familie pe lista căruia vrei
            să fii. În cerere trebuie să precizezi și numele medicului de la
            care vrei să pleci.
          </p>
          <p>
            Medicul de familie primitor are obligația că în termen de 15 zile
            lucrătoare să-l anunțe prin poștă despre plecarea ta pe fostul medic
            de familie. La rândul lui, acesta din urmă are și el obligația să
            transmită o copie certificată a fișei tale medicale, tot prin poștă,
            către noul medic de familie. Cabinetul medical de la care pleci va
            păstra însă fișa medicală originală.
          </p>
          <p>
            De asemenea, este important de precizat și că pacientul trebuie să
            prezinte noului medic de familie documentele care sa ii ateste
            calitatea de asigurat. Aceste documente difera in funcție de
            calitatea asiguratului: salariat, PFA, pensionar, student sau șomer.
          </p>
        </div>
      </Card>
    </Container>
  );
};

export default Inscriere;
