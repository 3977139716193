import React from "react";
import { Card, Container, Image } from "react-bootstrap";
import doctor_img from "../../../images/home-images/Constantin_Voicu.jpg";

const DeCeSaViiLaNoi = () => {
  return (
    <Container>
      <Card className="box-shadow-left m-3 p-3">
        <h1 className="m-4">De ce să vii la noi?</h1>
        <div>
          <Image src={doctor_img} className="image-container-right" />
          <h5> Stimați pacienți,</h5>
          <p>
            <span className="span-myclinic">My Clinic</span> își propune să va
            ofere asistență medicală de cea mai bună calitate, dar în primul
            rând să va menținem sănătoși.
          </p>
          <p>
            Ne bazăm pe stabilirea unei relații strânse între medic și pacient,
            devenind managerul stării dumneavoastră de sănătate.
          </p>
          <p>
            Alegând <span className="span-myclinic">My Clinic</span>c veți
            observă un stil nou de abordare a nevoilor dumneavoastră. Ne bazăm
            pe calitatea și eficientă actului medical prestat, ambianța plăcută
            și va oferim opțiunile unui mod de viață sănătos.
          </p>
          <p>
            Vă dorim să descoperiți siguranță și o viață de calitate că pacienți{" "}
            <span className="span-myclinic">My Clinic</span>!
          </p>
        </div>
      </Card>
    </Container>
  );
};

export default DeCeSaViiLaNoi;
